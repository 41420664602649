.a {
  font-family: "Poppins", sans-serif;
}

body{
    background-color: #2f516f
}

li {
  list-style: none;
  padding-top: 10px;
}

.one {
  border-collapse: separate;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border-spacing: 0px !important;
  font-variant: normal;
  display: ruby-text !important;
}

.submenu {
  max-height: 500px;
  overflow: auto;
}

.submenu::-webkit-scrollbar {
  display: none;
}


@media screen and (min-width: 1400px) {

	.page-wrapper {

		padding-left: 80px;

		padding-right: 80px;

	}

}

.wrapper.toggled .page-wrapper {
    margin-top: 70px;
}

.wrapper.toggled .nav-container {
  display: none;
}

@media screen and (max-width: 1280px) {

	.page-wrapper {

		margin-top: 70px;

	}

	.nav-container {

		position: fixed;

		width: 260px;

		right: 0;

		left: -260px;

		z-index: 16;

		border-bottom: 1px solid rgb(255 255 255 / 0.14);

		background-clip: border-box;

		background: #1f2c37;

		height: 100%;

		padding: 10px;

		display: flex;

		align-items: start;

		justify-content: flex-start;

		overflow-y: scroll;

		overflow-x: hidden;

	}

	.wrapper.toggled .nav-container {
		display: flex;
		left: 0px;
	}


	.wrapper.toggled .mobile-topbar-header {

		display: flex;

		align-items: center;

		height: 70px;

		background: #1f2c37;

        border-bottom: 1px solid rgb(255 255 255 / 0.14);

		background-clip: border-box;

		position: fixed;

		width: 260px;

		left: 0;

		top: 0;

		right: 0;

		z-index: 10;

		padding-left: 15px;

	}

	.wrapper.toggled .overlay {

		position: fixed;

		top: 0;

		right: 0;

		bottom: 0;

		left: 0;

		background: #000;

		opacity: 0.6;

		z-index: 15;

		display: block;

		cursor: move;

		transition: all .2s ease-out;

	}

	.left-topbar {

		width: auto;

	}

	.search-bar {

		padding: 0rem 1.4rem;

	}

	.email-header {

		height: auto;

	}

	.email-content {

		padding: 100px 0px 0px 0px;

	}

}

@media screen and (max-width: 1024px) {

	.top-header {

		left: 0px !important;

	}

	.search-bar .btn-search-back {

		background: rgb(255 255 255 / 0%);

        border: 1px solid rgb(255 255 255 / 0%);

		padding: 0 10px;

	}

	.search-arrow-back {

		display: flex;

		margin-right: 0px;

	}

	.search-btn-mobile {

		display: flex;

	}

	.search-bar {

		display: none;

	}

	.full-search-bar {

		display: flex;

		align-items: center;

		width: 100%;

		position: absolute;

		left: 0;

		z-index: 100;

		background: #171b1e;

		height: 70px;

		padding: 0rem 1.4rem;

	}

	.sidebar-wrapper {

		width: 260px!important;

		left: -260px;

		box-shadow: none;

		z-index: 12;

	}

	/* .sidebar-header {

		display:none;

	} */

	.page-content-wrapper {

		margin-left: 0px;

	}

	.wrapper.toggled .sidebar-wrapper {

		left: 0px;

		box-shadow: 0 .3rem 0.6rem rgba(0, 0, 0, .13);

		background-color: #171b1e;

		transition: all .2s ease-out;

		z-index: 16;

	}

	.wrapper.toggled .sidebar-header {

		display:flex;

        background: #171b1e;

	}

	.wrapper.toggled .page-content-wrapper {

		margin-left: 0px;

	}

	.wrapper.toggled .overlay {

		position: fixed;

		top: 0;

		right: 0;

		bottom: 0;

		left: 0;

		background: #fff;

		opacity: 0.5;

		z-index: 15;

		display: block;

		cursor: move;

		transition: all .2s ease-out;

	}

	.footer {

		margin-left: 0px;

	}

	.wrapper.toggled .footer {

		margin-left: 0px;

	}

	.chat-header {

		border-top-left-radius: 0.25rem;

	}

	.chat-footer {

		border-bottom-left-radius: 0.25rem;

	}

	.chat-sidebar {

		left: -370px;

	}

	.chat-content {

		margin-left: 0px;

	}

	.chat-header {

		left: 0px;

	}

	.chat-footer {

		left: 0px;

	}

	/* chat toggled css */

	.chat-toggled .chat-sidebar {

		left: 0px;
		background: #343a40;

	}

	.chat-toggled .overlay {

		position: absolute;

		top: 0;

		right: 0;

		bottom: 0;

		left: 340px;

		background: #fff;

		opacity: 0.5;

		z-index: 11;

		display: block;

		cursor: move;

		transition: all .3s ease-out;

	}

	.email-header {

		border-top-left-radius: 0.25rem;

	}

	.email-sidebar {

		left: -280px;
		background: #343a40;

	}

	.email-content {

		margin-left: 0px;

	}

	.email-header {

		left: 0px;

	}

	/* email toggled */

	.email-toggled .email-sidebar {

		left: 0px;

	}

	.email-toggled .overlay {

		position: absolute;

		top: 0;

		right: 0;

		bottom: 0;

		left: 250px;

		background: #000;

		opacity: 0.5;

		z-index: 9;

		display: block;

		cursor: move;

		transition: all .3s ease-out;

	}

}