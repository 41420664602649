body {
  font-family: "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #fdc134;
  text-decoration: none;
}

a:hover {
  color: #fdd067;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif;
}

/*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
  background: #213b52;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}

.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}

.back-to-top:hover {
  background: #fdc134;
  color: #fff;
}

.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

/*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
#header {
  z-index: 997;
  transition: all 0.5s;
  padding: 16px 0;
  background: #213b52;
}

#header .logo h1 {
  font-size: 24px;
  margin: 0;
  padding: 10px 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo h1 a span,
#header .logo h1 a:hover span {
  color: #fdc134;
}

#header .logo img {
  padding: 0;
  margin: 0;
  max-height: 40px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 14px;
  color: #adc7de;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar li:hover > a {
  color: #fff;
}

.navbar .getstarted {
  background: #213b52;
  color: #fff;
  padding: 4px 25px 6px 25px;
  margin-left: 30px;
  border-radius: 50px;
  border: 2px solid #fdc134;
}

.navbar .getstarted:hover {
  background: #fdc134;
  color: #213b52;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #3e6f9b;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #fdc134;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #fdc134;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(18, 33, 46, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a {
  padding: 10px 20px;
  font-size: 15px;
  color: #213b52;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #fdc134;
}

.navbar-mobile .getstarted {
  margin: 15px;
  display: block;
  border: 0;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #fdc134;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
#hero {
  width: 100%;
  padding: 0 0 0 0;
  background: #213b52;
  background: linear-gradient(180deg, #213b52 0%, #213c54 35%, #204564 100%);
}

#hero h1 {
  margin: 0 0 15px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #d2e0ed;
  font-family: "Poppins", sans-serif;
}

#hero h2 {
  color: #fff;
  margin-bottom: 30px;
  font-size: 22px;
}

#hero .btn-get-started {
  color: #213b52;
  border-radius: 50px;
  padding: 8px 35px 10px 35px;
  border: 2px solid #fdc134;
  transition: all ease-in-out 0.3s;
  display: inline-block;
  background: #fdc134;
}

#hero .btn-get-started:hover {
  background: transparent;
  color: #fff;
}

@media (max-width: 991px) {
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    width: 50%;
  }
}

@media (max-width: 768px) {
  #hero {
    -moz-text-align-last: center;
    text-align-last: center;
  }
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 70%;
  }
}

@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
}

/*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background: linear-gradient(180deg, #f2f6fa 0%, #fff 100%);
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}

.section-title h2 {
  font-size: 32px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  font-family: "Poppins", sans-serif;
  color: #213b52;
}

.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}

.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #fdc134;
  bottom: 0;
  left: calc(50% - 20px);
}

.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background-color: #f6f9fb;
  min-height: 40px;
}

.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
}

.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}

.breadcrumbs ol li + li {
  padding-left: 10px;
}

.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}

@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}
/*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
.about .content {
  padding: 30px 0;
}

.about .content h3 {
  font-weight: 700;
  font-size: 34px;
  color: #213b52;
}

.about .content p {
  margin-bottom: 0;
}

.about .content .icon-box {
  margin-top: 25px;
}

.about .content .icon-box h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
  color: #213b52;
}

.about .content .icon-box h4 a {
  color: #213b52;
  transition: 0.3s;
}

.about .content .icon-box h4 a:hover {
  color: #fdc134;
}

.about .content .icon-box i {
  font-size: 48px;
  float: left;
  color: #fdc134;
}

.about .content .icon-box p {
  font-size: 15px;
  color: #848484;
  margin-left: 60px;
}

.about .image {
  /* background: url("/frontend/static/assets/img/about.jpg") center center no-repeat; */
  background-size: cover;
  min-height: 400px;
}

@media (max-width: 667px) {
  .about .image img {
    max-width: 100%;
  }
}

/*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
.services {
  padding-bottom: 40px;
}

.services .card {
  border: 0;
  padding: 0 30px;
  margin-bottom: 60px;
  position: relative;
  background: transparent;
}

.services .card-img {
  width: calc(100% + 60px);
  margin-left: -30px;
  overflow: hidden;
  z-index: 9;
  border-radius: 0;
}

.services .card-img img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.services .card-body {
  z-index: 10;
  background: #fff;
  border-top: 4px solid #fff;
  padding: 30px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  margin-top: -60px;
  transition: 0.3s;
}

.services .card-title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.services .card-title a {
  color: #213b52;
  transition: 0.3s;
}

.services .card-text {
  color: #5e5e5e;
}

.services .read-more a {
  color: #777777;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: 0.3s;
}

.services .read-more a:hover {
  color: #fdc134;
}

.services .card:hover img {
  transform: scale(1.1);
}

.services .card:hover .card-body {
  border-color: #fdc134;
}

.services .card:hover .card-body .card-title a {
  color: #fdc134;
}

/*--------------------------------------------------------------
  # Features
  --------------------------------------------------------------*/
.features .content + .content {
  margin-top: 100px;
}

.features .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #213b52;
}

.features .content ul {
  list-style: none;
  padding: 0;
}

.features .content ul li {
  padding-bottom: 10px;
}

.features .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #fdc134;
}

.features .content p:last-child {
  margin-bottom: 0;
}
/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info-box {
  color: #444444;
  text-align: center;
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 20px 0 30px 0;
  background: #fff;
}

.contact .info-box i {
  font-size: 32px;
  color: #fdc134;
  border-radius: 50%;
  padding: 8px;
  border: 2px dotted #fff7e5;
}

.contact .info-box h3 {
  font-size: 20px;
  color: #777777;
  font-weight: 700;
  margin: 10px 0;
}

.contact .info-box p {
  padding: 0;
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}

.contact .php-email-form {
  box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
  padding: 30px;
  background: #fff;
}

.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}

.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}

.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
  background-color: #fdc134;
}

.contact .php-email-form input {
  padding: 10px 15px;
}

.contact .php-email-form textarea {
  padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
  background: #fdc134;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
  background: #fdd067;
}

@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
  color: #fff;
  font-size: 14px;
  position: relative;
}

#footer::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(26, 46, 64, 0.85);
  z-index: -1;
}

#footer .footer-top {
  position: relative;
  z-index: -1;
  text-align: center;
  padding: 80px 0;
}

#footer .footer-top h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
  position: relative;
  font-family: "Poppins", sans-serif;
  padding-bottom: 0;
  margin-bottom: 0;
}

#footer .footer-top p {
  font-size: 15;
  font-style: italic;
  margin: 30px 0 0 0;
  padding: 0;
}

#footer .footer-top .footer-newsletter {
  text-align: center;
  font-size: 15px;
  margin-top: 30px;
}

#footer .footer-top .footer-newsletter form {
  background: #fff;
  padding: 6px 10px;
  position: relative;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
}

#footer .footer-top .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}

#footer .footer-top .footer-newsletter form input[type="submit"] {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 16px;
  padding: 0 20px;
  background: #fdc134;
  color: #fff;
  transition: 0.3s;
  border-radius: 50px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

#footer .footer-top .footer-newsletter form input[type="submit"]:hover {
  background: #fcb102;
}

#footer .footer-top .social-links {
  margin-top: 30px;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #284864;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #fcb102;
  color: #fff;
  text-decoration: none;
}

#footer .footer-bottom {
  border-top: 1px solid #1a2e40;
  z-index: 2;
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

#footer .copyright {
  text-align: center;
  float: left;
}

#footer .credits {
  float: right;
  text-align: center;
  font-size: 13px;
}

@media (max-width: 768px) {
  #footer .copyright,
  #footer .credits {
    padding: 5px;
    float: none;
    text-align: center;
  }
}

.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 400px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 400px;
}
.gmap_iframe {
  height: 400px !important;
}
